import PropTypes from 'prop-types';
import React, { useState, useEffect, useRef, useContext } from 'react';
import scrollTo from 'gatsby-plugin-smoothscroll';

import AniLink from './animatelink';
import FadeLink from './fade-link';

import ThemeContext from '../context/theme-context';

import CodeAndSoul from '../components/svg/code-and-soul-logo';

import gsap from 'gsap';
import { TimelineLite } from 'gsap/gsap-core';

import { injectIntl } from 'gatsby-plugin-intl';

const Header = ({
    intl,
    showNavLinks,
    initialMenuToggle,
    hideContact,
    hasHeaderAnimation = false,
}) => {
    const {
        currentLocale,
        darkMode,
        isMobile,
        isMenuOpen,
        toggleMenu,
        headerAnimation,
    } = useContext(ThemeContext);

    const [close, setClose] = useState(false);
    const [hover, setHover] = useState(false);

    const [tl, setTl] = useState(false);

    const menuButtonRef = useRef(null);
    const headerRef = useRef(null);
    const firstItemRef = useRef(null);
    const middleItemRef = useRef(null);

    useEffect(() => {
        setTl(
            new TimelineLite({ paused: true })
                .to(middleItemRef.current, 0.1, {
                    opacity: 1,
                })
                .to(firstItemRef.current, 0.1, {
                    opacity: 1,
                })
        );
    }, []);

    useEffect(() => {
        if (tl) {
            if (hover) {
                tl.play();
            } else {
                tl.reverse();
            }
        }
    }, [hover]);

    useEffect(() => {
        const tlHeader = gsap.timeline();
        let delay = 0;

        if (hasHeaderAnimation && !isMobile) {
            delay = 10;
        }

        tlHeader
            .to(headerRef.current, {
                ease: 'expo.out',
                duration: 0.4,
                delay,
                opacity: 1,
            })
            .to(menuButtonRef.current, {
                ease: 'expo.bounce',
                duration: 0.2,
                // right: '-1.28rem',
            });

        if (hasHeaderAnimation && !isMobile) {
            if (headerAnimation) {
                tlHeader.play();
            }
        } else {
            tlHeader.play();
        }
    }, [hasHeaderAnimation, headerAnimation, isMobile]);

    const handleScrollTo = (event) => {
        event.preventDefault();

        const target = event.target.getAttribute('href');

        scrollTo(target);
    };

    const renderLink = (isHome, data, index) => {
        if (isHome) {
            return (
                <li key={index}>
                    <button
                        className="link"
                        href={data.link}
                        onClick={handleScrollTo}
                    >
                        {data.value}
                    </button>
                </li>
            );
        } else {
            return (
                <li key={index}>
                    <FadeLink link={`/${data.link}`} className="link">
                        {data.value}
                    </FadeLink>
                </li>
            );
        }
    };

    const renderNavLinks = (currentLocale) => {
        if (showNavLinks) {
            let links = [
                {
                    link: '#workSection',
                    value: intl.formatMessage({
                        id: 'work-navbar',
                    }),
                },
                {
                    link: '#whatWeDoSection',
                    value: intl.formatMessage({
                        id: 'what-we-do-navbar',
                    }),
                },
                {
                    link: '#blogSection',
                    value: intl.formatMessage({
                        id: 'blog-navbar',
                    }),
                },
            ];

            let path = '';

            if (typeof window !== 'undefined') {
                path = location.pathname.split('/');
            }

            return (
                <ul className="wrap-nav-links">
                    {links.map((data, index) =>
                        renderLink(path[2] == '', data, index)
                    )}

                    {!hideContact ? (
                        <AniLink
                            className={`link contact-link ${
                                isMenuOpen ? 'white' : ''
                            }`}
                            link={`/contact`}
                            title="Home"
                        >
                            {intl.formatMessage({
                                id: 'lets-talk-navbar',
                            })}
                        </AniLink>
                    ) : (
                        <></>
                    )}
                </ul>
            );
        }

        return '';
    };

    const renderMenuToggle = () => {
        return (
            <button
                type="button"
                className={`
                        ${
                            isMobile
                                ? 'btn-hamburger-line'
                                : 'btn-hamburger-dot'
                        } 
                        ${navColorIsWhite() ? '' : 'black'}
                    `}
                onClick={() => {
                    toggleMenu();
                    setClose(!close);
                }}
                onMouseEnter={() => setHover(!hover)}
                onMouseLeave={() => setHover(!hover)}
                ref={menuButtonRef}
            >
                <span className="first-line" ref={firstItemRef} />
                <span className="middle-line" ref={middleItemRef} />
                <span className="last-line" />
            </button>
        );
    };

    const navColorIsWhite = () => {
        if (typeof window === 'undefined' || !window.document) {
            return false;
        }

        const sections = document.querySelectorAll('*[datanav="white"]');

        for (const section of sections) {
            const { pageYOffset } = window;
            const { offsetTop, offsetHeight } = section;

            if (
                offsetTop - pageYOffset <= 0 &&
                pageYOffset < offsetHeight + offsetTop
            ) {
                return true;
            }
        }

        return false;
    };

    return (
        <header className="header">
            <div className="container" ref={headerRef}>
                <div className="wrap-image">
                    <AniLink className="link" link={`/`} title="Home">
                        <CodeAndSoul darkMode={darkMode} />
                    </AniLink>
                </div>

                <nav className="navbar">{renderNavLinks(currentLocale)}</nav>
            </div>

            <div
                className={`
                    right-links
                    fixed
                    color-black
                `}
            >
                {renderMenuToggle()}
            </div>
        </header>
    );
};

Header.propTypes = {
    showNavLinks: PropTypes.bool,
};

Header.defaultProps = {
    showNavLinks: false,
    initialMenuToggle: true,
};

export default injectIntl(Header);
