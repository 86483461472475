const colors = {
    black: '#151515',
    white: '#ffffff',
    seaGreen: '#00B01E',
    mediumSeaSreen: '#00B01E',
    dandelion: '#0E2477',
    gainsboro: '#DDDDDD',
}

export default colors
