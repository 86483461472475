import React from 'react'
import PropTypes from 'prop-types'
import AniLink from 'gatsby-plugin-transition-link/AniLink'
import { injectIntl } from 'gatsby-plugin-intl'

import colors from '../styles/colors'

const { seaGreen } = colors

class AnimateLink extends React.Component {
    render() {
        const { intl, link, title, className, children, ...other } = this.props

        return (
            <AniLink
                cover
                bg={seaGreen}
                direction="left"
                to={`/${intl.locale}${link}`}
                title={title}
                className={className}
                {...other}
            >
                {children}
            </AniLink>
        )
    }
}

AnimateLink.defaultProps = {
    title: '',
    className: '',
}

AnimateLink.propTypes = {
    link: PropTypes.string.isRequired,
    title: PropTypes.string,
    className: PropTypes.string,
}

export default injectIntl(AnimateLink)
