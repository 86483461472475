/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from 'react';
import PropTypes from 'prop-types';

import ThemeContext from '../context/theme-context';
import Header from './header';
import Footer from './footer';

import '../styles/scss/index.scss';

const Layout = ({
    children,
    hideFooter = false,
    hideContact = false,
    showNavLinks,
    initialMenuToggle,
    hasHeaderAnimation,
}) => {
    return (
        <ThemeContext.Consumer>
            {(theme) => {
                const {
                    // toggleMenu,
                    // isMenuOpen,
                    darkMode,
                    // setDarkMode,
                    // isMobile,
                    // currentLocale,
                    // changeLocale
                } = theme;
                return (
                    <div className="wrapper">
                        <Header
                            showNavLinks={showNavLinks}
                            initialMenuToggle={initialMenuToggle}
                            hideContact={hideContact}
                            hasHeaderAnimation={hasHeaderAnimation}
                        />

                        <main>{children}</main>

                        {!hideFooter ? <Footer darkMode={darkMode} /> : <></>}

                        {/* <ButtonDarkMode setDarkMode={setDarkMode} darkMode={darkMode} /> */}
                    </div>
                );
            }}
        </ThemeContext.Consumer>
    );
};

Layout.propTypes = {
    children: PropTypes.node.isRequired,
};

export default Layout;
